import { Member,
  MemberName,
  MemberQuote,
  MemberKnowledges,
  MemberKnowledgesKnowledge,
  MemberActions
} from "./member.styled";
import type { MemberType } from "../../data/members/types/members.type";
import type { TitleType } from "../title/types/title.type";
import type { ParagraphType } from "../paragraph/types/paragraph.type";
import type { ButtonType } from "../button/types/button.type";
import AvatarComponent from "../avatar/avatar.component";
import TitleComponent from "../title/title.component";
import ParagraphComponent from "../paragraph/paragraph.component";
import KnowledgeComponent from "../knowledge/knowledge.component";
import ButtonComponent from "../button/button.component";
import { AVATAR_SIZES_NAMES, COLORS, KNOWLEDGE_SIZES, PARAGRAPH_SIZES_NAMES, TITLE_SIZES_NAMES } from "../../utils";

const MemberComponent = ({color, image, knowledges, name, quote, roles}: MemberType) => {
  const nameProps: TitleType = {
    align: 'center',
    color: color,
    level: 3,
    size: TITLE_SIZES_NAMES.memberName,
    value: name
  };

  const roleProps: ParagraphType = {
    align: 'center',
    color: COLORS.text,
    size: PARAGRAPH_SIZES_NAMES.memberRole,
    values: roles
  };
  
  const quoteProps: ParagraphType = {
    align: 'center',
    color: COLORS.text,
    italic: true,
    size: PARAGRAPH_SIZES_NAMES.memberQuote,
    thin: true,
    values: [quote]
  };

  const experiencesButtonProps: ButtonType = {
    icon: 'list',
    value: 'Experiencias'
  };

  const cvButtonProps: ButtonType = {
    icon: 'file',
    value: 'CV'
  };
  
  return (
    <Member>
      <AvatarComponent image={image} size={AVATAR_SIZES_NAMES.member} />
      <MemberName>
        <TitleComponent {...nameProps}/>
      </MemberName>
      <ParagraphComponent {...roleProps}/>
      <MemberQuote>
        <ParagraphComponent {...quoteProps}/>
      </MemberQuote>
      <MemberKnowledges>
        {knowledges.map(knowledge => (
          <MemberKnowledgesKnowledge key={knowledge}>
            <KnowledgeComponent name={knowledge} size={KNOWLEDGE_SIZES.m}/>
          </MemberKnowledgesKnowledge>
        ))}
      </MemberKnowledges>
      <MemberActions>
        <ButtonComponent {...experiencesButtonProps}/>
        <ButtonComponent {...cvButtonProps}/>
      </MemberActions>
    </Member>
  );
};

export default MemberComponent;