import { MemberInfo } from './member-info.styled';

const MemberInfoView = (props: any): any => {
	const { name } = props;
	return (
		<MemberInfo>
			<span>{name}</span>
		</MemberInfo>
	);
};

export default MemberInfoView;
