import { TitleTextH1, TitleTextH2, TitleTextH3 } from './title.styled';
import type { TitleType } from './types/title.type';

const TitleComponent = (props: TitleType) => {
	return (
		<>
			{props.level === 1 && <TitleTextH1 {...props}>{props.value}</TitleTextH1>}
			{props.level === 2 && <TitleTextH2 {...props}>{props.value}</TitleTextH2>}
			{props.level === 3 && <TitleTextH3 {...props}>{props.value}</TitleTextH3>}
		</>
	);
};

export default TitleComponent;
