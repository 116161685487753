const STANDARD_SIZES = {
  xs: '3rem',
  s: '4rem',
  m: '7.5rem',
  l: '9.375rem',
  xl: '12.5rem'
};

export const AVATAR_SIZES_NAMES = {
  experienceParticipant: 'experienceParticipant',
  experienceParticipantTablet: 'experienceParticipantTablet',
  experienceParticipantMobile: 'experienceParticipantMobile',
  member: 'member',
  memberTablet: 'memberTablet',
  memberMobile: 'memberMobile',
  memberExperiences: 'memberExperiences',
  memberExperiencesTablet: 'memberExperiencesTablet',
  memberExperiencesMobile: 'memberExperiencesMobile',
  menu: 'menu',
  menuTablet: 'menuTablet',
  menuMobile: 'menuMobile'
};

export const AVATAR_SIZES = {
  experienceParticipant: STANDARD_SIZES.s,
  experienceParticipantTablet: STANDARD_SIZES.s,
  experienceParticipantMobile: STANDARD_SIZES.xs,
  member: STANDARD_SIZES.l,
  memberTablet: STANDARD_SIZES.m,
  memberMobile: STANDARD_SIZES.m,
  memberExperiences: STANDARD_SIZES.xl,
  memberExperiencesTablet: STANDARD_SIZES.xl,
  memberExperiencesMobile: STANDARD_SIZES.m,
  menu: STANDARD_SIZES.s,
  menuTablet: STANDARD_SIZES.s,
  menuMobile: STANDARD_SIZES.s
};