export const KNOWLEDGES = {
  adobeXD: 'adobe-xd',
  angular: 'angular',
  cplusplus: 'cplusplus',
  css3: 'css3',
  figma: 'figma',
  git: 'git',
  html5: 'html5',
  ionic: 'ionic',
  java: 'java',
  js: 'js',
  kubernetes: 'kubernetes',
  mysql: 'mysql',
  photoshop: 'photoshop',
  php: 'php',
  react: 'react',
  sass: 'sass',
  scrum: 'scrum',
  sketch: 'sketch',
  spring: 'spring',
  symfony: 'symfony',
  unrealEngine: 'unreal-engine',
  vue: 'vue'
};

export const KNOWLEDGES_TITLES = {
  'adobe-xd': 'Adobe XD',
  angular: 'Angular',
  cplusplus: 'C++',
  css3: 'CSS3',
  figma: 'Figma',
  git: 'Git',
  html5: 'HTML5',
  ionic: 'Ionic',
  java: 'Java',
  js: 'JavaScript',
  kubernetes: 'Kubernetes',
  mysql: 'MySQL',
  photoshop: 'Adobe Photoshop',
  php: 'PHP',
  react: 'React',
  sass: 'SASS',
  scrum: 'Scrum',
  sketch: 'Sketch',
  spring: 'Spring',
  symfony: 'Symfony',
  'unreal-engine': 'Unreal Engine',
  vue: 'Vue'
}