import styled from 'styled-components';
import { COLORS, DEVICES } from '../../utils';

export const Landing = styled.article`
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	justify-content: center;
	width: 100%;
`;

export const LandingIntro = styled.section`
	align-items: center;
	background-image: url('../../assets/images/backgrounds/landing-intro-background.svg');
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	position: relative;
	width: 100%;
	@media (${DEVICES.desktop}) {
		background-size: initial;
	}
	@media (${DEVICES.laptopL}) {
		padding: 3rem;
	}
	@media (${DEVICES.tablet}) {
		padding: 1rem;
	}
`;

export const LandingIntroTitle = styled.div`
	margin: 0 0 1rem 0;
	position: relative;
	right: 2.25rem;
	@media (${DEVICES.laptopL}) {
		right: 1.0625rem;
	}
	@media (${DEVICES.tablet}) {
		right: .5625rem;
	}
`;

export const LandingIntroArrow = styled.img`
	bottom: 4rem;
	cursor: pointer;
	position: absolute;
	@media (${DEVICES.laptopL}) {
		bottom: 6rem;
	}
	@media (${DEVICES.tablet}) {
		bottom: 8rem;
	}
`;

export const LandingTeam = styled.section`
	align-items: center;
	background-color: ${COLORS.white};
	background-image: url('../../assets/images/backgrounds/landing-team-background.svg');
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
	padding: 10rem 2.5rem;
	width: 100%;
	@media (${DEVICES.desktop}) {
		background-size: initial;
	}
	@media (${DEVICES.tablet}) {
		padding: 10rem 1rem 5rem;
	}
`;

export const LandingTeamParagraph = styled.div`
	margin: 2rem 0 5rem 0;
	text-align: center;
	width: 66rem;
	@media (${DEVICES.laptopL}) {
		margin: 1.5rem 0 3rem 0;
		width: 42.5rem;
	}
	@media (${DEVICES.tablet}) {
		margin: 1rem 0 2rem 0;
		text-align: left;
		width: 100%;
	}
`;

export const LandingTeamMembers = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 5rem;
	width: 100%;
	@media (${DEVICES.tablet}) {
		column-gap: 2rem;
	}
`;

export const LandingExperiences = styled.section`
	display: flex;
	height: 100vh;
	width: 100%;
	@media (${DEVICES.laptopL}) {
		flex-direction: column;
	}
`;

export const LandingExperiencesPhoto = styled.div`
	display: flex;
	position: relative;
	width: calc(100% - 40rem);
	@media (${DEVICES.laptopL}) {
		height: calc(100% - 26.625rem);
		width: 100%;
	}
	@media (${DEVICES.tablet}) {
		height: calc(100% - 15.8125rem);
	}
`;

export const LandingExperiencesPhotoImage = styled.img`
	height: 100%;
	object-fit: cover;
	object-position: center;
	width: 100%;
	@media (${DEVICES.laptopL}) {
		object-position: 0% 40%;
	}
	@media (${DEVICES.tablet}) {
		object-position: 40%;
	}
`;

export const LandingExperiencesPhotoGradient = styled.div`
	background: linear-gradient(to right, transparent, ${COLORS.white});
	bottom: 0;
	height: 100%;
	position: absolute;
	right: 0;
	width: 20rem;
	@media (${DEVICES.laptopL}) {
		background: linear-gradient(to bottom, transparent, ${COLORS.white});
		height: 17.75rem;
		width: 100%;
	}
	@media (${DEVICES.tablet}) {
		height: 10.5rem;
	}
`;

export const LandingExperiencesInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 8rem 4rem;
	width: 40rem;
	@media (${DEVICES.laptopL}) {
		height: 26.625rem;
		padding: 4rem 2rem;
		width: 100%;
	}
	@media (${DEVICES.tablet}) {
		height: 15.8125rem;
		padding: 1rem;
	}
`;

export const LandingExperiencesInfoHeader = styled.div`
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	@media (${DEVICES.laptopL}) {
		align-items: flex-start;
	}
`;

export const LandingExperiencesInfoHeaderParagraph = styled.div`
	margin: 1.5rem 0 0 0;
	text-align: right;
	@media (${DEVICES.laptopL}) {
		margin: 1.25rem 0 0 0;
		text-align: left;
	}
	@media (${DEVICES.tablet}) {
		margin: 1rem 0 0 0;
	}
`;

export const LandingExperiencesInfoButton = styled.div`
	width: 100%;
	@media (${DEVICES.laptopL}) {
		width: 31.375rem;
	}
	@media (${DEVICES.tablet}) {
		width: 100%;
	}
`;
