import styled from 'styled-components';
import { COLORS, DEVICES } from '../../utils';

export const Button = styled.div`
  align-items: center;
  background: linear-gradient(to right, ${COLORS.magenta} 0%, ${COLORS.red} 25%, ${COLORS.orange} 50%, ${COLORS.yellow} 75%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  &.fixed {
    width: 100%;
  }
`;

export const ButtonContent = styled.div`
  background-color: ${COLORS.background};
  display: flex;
  justify-content: center;
  margin: .125rem;
  min-height: 2.75rem;
  min-width: 2.75rem;
  padding: .4375rem 1rem;
  &.big {
    padding: 1.25rem 4rem;
  }
  &.fixed {
    width: 100%;
  }
  &.no-value {
    padding: .5rem;
  }
  @media (${DEVICES.tablet}) {
    &.big {
      padding: 1rem;
    }
  }
`;

export const ButtonIcon = styled.img`
  margin: 0 .5rem 0 0;
  &.no-value {
    margin: 0;
  }
`;
