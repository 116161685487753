import { Button, ButtonContent, ButtonIcon } from './button.styled';
import ParagraphComponent from '../paragraph/paragraph.component';
import type { ButtonType } from './types/button.type';
import type { ParagraphType } from '../paragraph/types/paragraph.type';
import { COLORS, PARAGRAPH_SIZES_NAMES } from '../../utils';

const ButtonComponent = ({big, fixed, icon, value}: ButtonType) => {
	const buttonTextProps: ParagraphType = {
		align: 'center',
		color: COLORS.text,
		size: big ? PARAGRAPH_SIZES_NAMES.bigButtonText : PARAGRAPH_SIZES_NAMES.buttonText,
		values: [value || '']
	};

	const buttonClasses = (): string => {
		let classes = '';
		classes += big ? 'big ' : '';
		classes += fixed ? 'fixed ' : '';
		classes += !value ? 'no-value ' : '';
		return classes.trimEnd();
	};
	
	return (
		<Button className={fixed ? 'fixed' : ''}>
			<ButtonContent className={buttonClasses()}>
				{icon && <ButtonIcon className={!value ? 'no-value' : ''} src={`../../../assets/images/icons/${icon}.svg`}/>}
				{value && <ParagraphComponent {...buttonTextProps} />}
			</ButtonContent>
		</Button>
	);
};

export default ButtonComponent;
