export const COLORS = {
	magenta: '#ff2065',
	red: '#ff2121',
	orange: '#ff8323',
	yellow: '#ffcf22',
	background: '#ffeeee',
	text: '#330a0a',
	black: '#000000',
	white: '#ffffff'
};
