import {
	Landing,
	LandingIntro,
	LandingIntroArrow,
	LandingIntroTitle,
	LandingTeam,
	LandingTeamParagraph,
	LandingTeamMembers,
	LandingExperiences,
	LandingExperiencesPhoto,
	LandingExperiencesInfo,
	LandingExperiencesInfoHeader,
	LandingExperiencesInfoHeaderParagraph,
	LandingExperiencesPhotoImage,
	LandingExperiencesPhotoGradient,
	LandingExperiencesInfoButton
} from './landing.styled';
import TitleComponent from '../../components/title/title.component';
import ParagraphComponent from '../../components/paragraph/paragraph.component';
import MemberComponent from '../../components/member/member.component';
import ButtonComponent from '../../components/button/button.component';
import type { TitleType } from '../../components/title/types/title.type';
import type { ParagraphType } from '../../components/paragraph/types/paragraph.type';
import type { ButtonType } from '../../components/button/types/button.type';
import { COLORS, PARAGRAPH_SIZES_NAMES, TITLE_SIZES_NAMES } from '../../utils';
import { MEMBERS } from '../../data';

const introTitleProps: TitleType = {
	color: COLORS.background,
	level: 1,
	size: TITLE_SIZES_NAMES.title,
	value: 'Wildsoft'
};

const introParagraphProps: ParagraphType = {
	align: 'center',
	color: COLORS.background,
	size: PARAGRAPH_SIZES_NAMES.subTitle,
	values: ['Realizar proyectos personales nos eleva como profesionales']
};

const teamTitleProps: TitleType = {
	color: COLORS.magenta,
	level: 2,
	size: TITLE_SIZES_NAMES.sectionTitle,
	value: 'El equipo'
};

const teamParagraphProps: ParagraphType = {
	color: COLORS.text,
	size: PARAGRAPH_SIZES_NAMES.sectionDescription,
	values: [
		'Hemos sido compañeros de carrera, de trabajo y hasta de piso.',
		`Durante muchos años hemos vivido multitud de experiencias además de cultivar lazos
		y son estos lazos los que nos impulsan a realizar proyectos con pasión.`
	]
};

const experiencesTitleProps: TitleType = {
	color: COLORS.magenta,
	level: 2,
	size: TITLE_SIZES_NAMES.sectionTitle,
	value: 'Experiencias'
};

const experiencesParagraphProps: ParagraphType = {
	color: COLORS.text,
	size: PARAGRAPH_SIZES_NAMES.sectionDescription,
	values: ['Nos mueve crear ilusión con lo que hacemos, tanto para un miembro de Wildsoft como para el público.']
};

const experiencesButtonProps: ButtonType = {
	big: true,
	fixed: true,
	value: 'Explora nuestras experiencias'
};

const scrollToTeam = (): void => {
	window.scrollTo(0, window.innerHeight + 80);
};

const LandingView = (): any => {
	return (
		<Landing>
			<LandingIntro>
				<LandingIntroTitle>
					<TitleComponent {...introTitleProps} />
				</LandingIntroTitle>
				<ParagraphComponent {...introParagraphProps} />
				<LandingIntroArrow src="../../assets/images/icons/long-arrow-down.svg" onClick={scrollToTeam} />
			</LandingIntro>
			<LandingTeam>
				<TitleComponent {...teamTitleProps} />
				<LandingTeamParagraph>
					<ParagraphComponent {...teamParagraphProps} />
				</LandingTeamParagraph>
				<LandingTeamMembers>
					{MEMBERS.map(member => (<MemberComponent key={member.name} {...member} />))}
				</LandingTeamMembers>
			</LandingTeam>
			<LandingExperiences>
				<LandingExperiencesPhoto>
					<LandingExperiencesPhotoImage src="../../../assets/images/backgrounds/landing-experiences-background.jpg" />
					<LandingExperiencesPhotoGradient />
				</LandingExperiencesPhoto>
				<LandingExperiencesInfo>
					<LandingExperiencesInfoHeader>
						<TitleComponent {...experiencesTitleProps} />
						<LandingExperiencesInfoHeaderParagraph>
							<ParagraphComponent {...experiencesParagraphProps} />
						</LandingExperiencesInfoHeaderParagraph>
					</LandingExperiencesInfoHeader>
					<LandingExperiencesInfoButton>
						<ButtonComponent {...experiencesButtonProps} />
					</LandingExperiencesInfoButton>
				</LandingExperiencesInfo>
			</LandingExperiences>
		</Landing>
	);
};

export default LandingView;
