import styled from "styled-components";
import { DEVICES } from "../../utils";

export const Member = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 23.0625rem;
  @media (${DEVICES.laptopL}) {
		width: 20rem;
	}
`;

export const MemberName = styled.div`
  margin: 2rem 0 1rem 0;
  user-select: text;
`;

export const MemberQuote = styled.div`
  height: 4.3125rem;
  margin: .5rem 0 .5rem;
`;

export const MemberKnowledges = styled.div`
  display: grid;
  grid-template: repeat(2, 50%) / repeat(5, 20%);
`;

export const MemberKnowledgesKnowledge = styled.div`
  display: flex;
  padding: .5rem;
`;

export const MemberActions = styled.div`
  align-items: center;
  display: flex;
  column-gap: 1rem;
  justify-content: center;
  margin: 2rem 0 0 0;
`;
