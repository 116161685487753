import { MemberType } from "./types/members.type";
import { COLORS } from "../../utils";
import { KNOWLEDGES } from "../../utils/knowledges.utils";
import { EXPERIENCES } from "../experiences/experiences.data";

export const MEMBERS: MemberType[] = [{
  color: COLORS.magenta,
  email: 'fiticuti@gmail.com',
  experiences: [
    EXPERIENCES.awswTheSixthChapter,
    EXPERIENCES.khEdu,
    EXPERIENCES.youInstant,
    EXPERIENCES.wildsoft
  ],
  image: '../../assets/images/members/samu.jpg',
  knowledges: [
    KNOWLEDGES.photoshop,
    KNOWLEDGES.adobeXD,
    KNOWLEDGES.figma,
    KNOWLEDGES.html5,
    KNOWLEDGES.css3,
    KNOWLEDGES.js,
    KNOWLEDGES.sass,
    KNOWLEDGES.angular,
    KNOWLEDGES.react,
    KNOWLEDGES.git
  ],
  name: 'Samuel díaz millán',
  quote: '"Me apasiona la formación e idear las interfaces que llevarán al éxito los productos de la empresa."',
  roles: ['UX/UI Designer', 'Front-end Developer']
},
{
  color: COLORS.red,
  email: 'josemanuel.betanzos@icloud.com',
  experiences: [
    EXPERIENCES.khEdu,
    EXPERIENCES.fuji,
    EXPERIENCES.youInstant,
    EXPERIENCES.nierThirty
  ],
  image: '../../assets/images/members/jose.jpg',
  knowledges: [
    KNOWLEDGES.html5,
    KNOWLEDGES.css3,
    KNOWLEDGES.js,
    KNOWLEDGES.sass,
    KNOWLEDGES.angular,
    KNOWLEDGES.react,
    KNOWLEDGES.vue,
    KNOWLEDGES.ionic,
    KNOWLEDGES.git
  ],
  name: 'José manuel lópez betanzos',
  quote: '"Disfruto desarrollando aplicaciones Front, buscando soluciones que cumplan las expectativas del cliente."',
  roles: ['Front-end Developer', ' ']
},
{
  color: COLORS.orange,
  email: 'edu191191@gmail.com',
  experiences: [
    EXPERIENCES.youInstant,
    EXPERIENCES.nierThirty
  ],
  image: '../../assets/images/members/edu.jpg',
  knowledges: [
    KNOWLEDGES.html5,
    KNOWLEDGES.css3,
    KNOWLEDGES.js,
    KNOWLEDGES.java,
    KNOWLEDGES.spring,
    KNOWLEDGES.php,
    KNOWLEDGES.symfony,
    KNOWLEDGES.mysql,
    KNOWLEDGES.git,
    KNOWLEDGES.scrum
  ],
  name: 'Eduardo acuña coronado',
  quote: '"Propongo soluciones simples y directas a problemas complejos."',
  roles: ['Back-end Developer', 'PHP/Java Programmer']
},
{
  color: COLORS.yellow,
  email: 'antoniogarciadequevedo@gmail.com',
  experiences: [
    EXPERIENCES.khEdu
  ],
  image: '../../assets/images/members/tony.jpg',
  knowledges: [
    KNOWLEDGES.photoshop,
    KNOWLEDGES.js,
    KNOWLEDGES.java,
    KNOWLEDGES.spring,
    KNOWLEDGES.php,
    KNOWLEDGES.cplusplus,
    KNOWLEDGES.kubernetes,
    KNOWLEDGES.git,
    KNOWLEDGES.unrealEngine
  ],
  name: 'Tony quevedo',
  quote: '"Cuando el objetivo te parezca difícil, no cambies de objetivo sino busca un nuevo camino para llegar a él."',
  roles: ['Unreal 3D Designer', 'Back-end Developer']
}];