import styled from "styled-components";
import { AvatarType } from "./types/avatar.type";
import { AVATAR_SIZES, DEVICES } from "../../utils";

const avatarSizes = AVATAR_SIZES as any as Record<string, string>;

export const Avatar = styled.img.attrs((props: AvatarType) => props)`
  border-radius: 50%;
  height: ${(props: AvatarType) => avatarSizes[props.size]};
  object-fit: cover;
  width: ${(props: AvatarType) => avatarSizes[props.size]};
  @media (${DEVICES.laptopL}) {
		height: ${(props: AvatarType) => avatarSizes[props.size + 'Tablet']};
		width: ${(props: AvatarType) => avatarSizes[props.size + 'Tablet']};
	}
	@media (${DEVICES.tablet}) {
		height: ${(props: AvatarType) => avatarSizes[props.size + 'Mobile']};
		width: ${(props: AvatarType) => avatarSizes[props.size + 'Mobile']};
	}
`;