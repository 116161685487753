import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingView from '../views/landing/landing.view';
import ExperienceView from '../views/experiences/experiences.view';
import TeamView from '../views/team/team.view';
import CVView from '../views/cv/cv.view';

function Wildsoft() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<LandingView />} />
				<Route path="/experiences" element={<ExperienceView />} />
				<Route path="/team" element={<TeamView />} />
				<Route path="/cv" element={<CVView />} />
				<Route path="*" element={<LandingView />} />
			</Routes>
		</BrowserRouter>
	);
}

export default Wildsoft;
