// Styles
import { Experiences } from './experiences.styled';

const ExperienceView = (): any => {
	return (
		<Experiences>
			<span>Hola</span>
		</Experiences>
	);
};

export default ExperienceView;
