import { ParagraphText } from "./paragraph.styled";
import { ParagraphType } from "./types/paragraph.type";

const ParagraphComponent = (props: ParagraphType) => {
  return (
    <>
      {props.values.map(value => <ParagraphText key={value} {...props}>{value}</ParagraphText>)}
    </>
  )
};

export default ParagraphComponent;