export const WIDTHS = {
	mobile: 425,
	tablet: 768,
	laptop: 1024,
	laptopL: 1440,
	desktop: 1920,
	desktopL: 2560
};

export const DEVICES = {
	mobile: `(max-width: ${WIDTHS.mobile + 'px'})`,
	tablet: `(max-width: ${WIDTHS.tablet + 'px'})`,
	laptop: `(max-width: ${WIDTHS.laptop + 'px'})`,
	laptopL: `(max-width: ${WIDTHS.laptopL + 'px'})`,
	desktop: `(max-width: ${WIDTHS.desktop + 'px'})`,
	desktopL: `(max-width: ${WIDTHS.desktopL + 'px'})`
};
