import styled from 'styled-components';
import { DEVICES, TITLE_SIZES } from '../../utils';
import type { TitleType } from './types/title.type';

const titleSizes = TITLE_SIZES as any as Record<string, string>;

export const TitleTextH1 = styled.h1.attrs((props: TitleType) => props)`
	color: ${(props: TitleType) => props.color};
	font-family: American Kestrel;
	font-size: ${(props: TitleType) => titleSizes[props.size]};
	margin: 0;
	${(props: TitleType) => props.align && `text-align: ${props.align};`}
	@media (${DEVICES.laptopL}) {
		font-size: ${(props: TitleType) => titleSizes[props.size + 'Tablet']};
	}
	@media (${DEVICES.tablet}) {
		font-size: ${(props: TitleType) => titleSizes[props.size + 'Mobile']};
	}
`;

export const TitleTextH2 = styled.h2.attrs((props: TitleType) => props)`
	color: ${(props: TitleType) => props.color};
	font-family: American Kestrel;
	font-size: ${(props: TitleType) => titleSizes[props.size]};
	margin: 0;
	${(props: TitleType) => props.align && `text-align: ${props.align};`}
	@media (${DEVICES.laptopL}) {
		font-size: ${(props: TitleType) => titleSizes[props.size + 'Tablet']};
	}
	@media (${DEVICES.tablet}) {
		font-size: ${(props: TitleType) => titleSizes[props.size + 'Mobile']};
	}
`;

export const TitleTextH3 = styled.h3.attrs((props: TitleType) => props)`
	color: ${(props: TitleType) => props.color};
	font-family: American Kestrel;
	font-size: ${(props: TitleType) => titleSizes[props.size]};
	margin: 0;
	${(props: TitleType) => props.align && `text-align: ${props.align};`}
	@media (${DEVICES.laptopL}) {
		font-size: ${(props: TitleType) => titleSizes[props.size + 'Tablet']};
	}
	@media (${DEVICES.tablet}) {
		font-size: ${(props: TitleType) => titleSizes[props.size + 'Mobile']};
	}
`;
