// Styles

import { CV } from './cv.styled';

const CVView = (): any => {
	return (
		<CV>
			<span>Hola</span>
		</CV>
	);
};

export default CVView;
