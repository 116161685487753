// Vendor
import { Routes, Route } from 'react-router-dom';
// Components
import MemberInfoView from './components/member-info/member-info.view';
// Styles
import { Team } from './team.styled';

const TeamView = (): any => {
	return (
		<Team>
			<Routes>
				<Route path="/edu" element={<MemberInfoView name={'Edu'} />} />
				<Route
					path="/jose"
					element={<MemberInfoView name={'José'} />}
				/>
				<Route
					path="/samu"
					element={<MemberInfoView name={'Samu'} />}
				/>
				<Route
					path="/tony"
					element={<MemberInfoView name={'Tony'} />}
				/>
				<Route path="*" element={<MemberInfoView name={'Edu'} />} />
			</Routes>
		</Team>
	);
};

export default TeamView;
