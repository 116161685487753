import type { experienceType } from "./types/experience.type";
import { KNOWLEDGES } from "../../utils/knowledges.utils";

export const EXPERIENCES: {
  awswTheSixthChapter: experienceType,
  khEdu: experienceType,
  fuji: experienceType,
  youInstant: experienceType,
  nierThirty: experienceType,
  wildsoft: experienceType
} = {
  awswTheSixthChapter: {
    background: 'awsw-the-sixth-chapter',
    cta: 'Juega AwSW: TSC',
    description: `
      Ampliación de la historia original recreando con mimo los elementos de Angels with Scaly Wings en tecnologías
      web para ser disfrutado por sus fans tanto en navegador como en dispositivos móviles.
    `,
    genre: 'Videojuego - Novela visual',
    knowledges: [
      KNOWLEDGES.photoshop,
      KNOWLEDGES.html5,
      KNOWLEDGES.css3,
      KNOWLEDGES.js,
    ],
    music: '../../../assets/musics/awsw-menu.mp3',
    name: 'Awsw: the sixth chapter',
    participants: [{
      image: '../../../assets/images/members/samu.jpg',
      name: 'Samuel',
      roles: ['Maquetación', 'Programación', 'Traducción'],
      time: 350
    }],
    link: {
      external: false,
      url: 'awswthesixthchapter'
    }
  },
  khEdu: {
    background: 'kingdom-hearts-edu-version',
    cta: "Juega KH: Edu's version",
    description: `
      Regalo de cumpleaños para nuestro querido Edu, se trata de una adaptación de la saga Kingdom Hearts con minijuegos,
      fases arcade y preguntas con un emotivo final.
    `,
    genre: 'Videojuego - Arcade | Puzzle',
    knowledges: [
      KNOWLEDGES.photoshop,
      KNOWLEDGES.html5,
      KNOWLEDGES.css3,
      KNOWLEDGES.js,
      KNOWLEDGES.sass,
      KNOWLEDGES.angular,
      KNOWLEDGES.git
    ],
    music: '../../../assets/musics/kh3-menu.mp3',
    name: "Kingdom hearts: edu's version",
    participants: [{
      image: '../../../assets/images/members/samu.jpg',
      name: 'Samuel',
      roles: ['Maquetación', 'Programación'],
      time: 50
    },
    {
      image: '../../../assets/images/members/jose.jpg',
      name: 'José Manuel',
      roles: ['Maquetación', 'Programación'],
      time: 80
    },
    {
      image: '../../../assets/images/members/tony.jpg',
      name: 'Tony',
      roles: ['Ilustración'],
      time: 5
    }],
    link: {
      external: false,
      url: 'noviembre'
    }
  },
  fuji: {
    background: 'fuji',
    cta: 'Explora Fuji',
    description: `
      Creado como parte de un regalo de cumpleaños, nos muestra una consola y acaba simulando la interfaz de Mac Os 8.
      En ella se proponen una serie de acertijos cargados de mucho humor. Prueba con "peach".
    `,
    genre: 'Simulación - Sistema operativo Apple',
    knowledges: [
      KNOWLEDGES.html5,
      KNOWLEDGES.css3,
      KNOWLEDGES.js,
      KNOWLEDGES.sass,
      KNOWLEDGES.angular,
      KNOWLEDGES.git
    ],
    name: 'Fuji',
    participants: [{
      image: '../../../assets/images/members/jose.jpg',
      name: 'José Manuel',
      roles: ['Maquetación', 'Programación'],
      time: 30
    }],
    link: {
      external: false,
      url: 'fuji'
    }
  },
  youInstant: {
    background: 'youinstant',
    cta: 'Explora YouInstant',
    description: `Comparte videos de YouTube a tus amigos en el instante exacto con tu móvil.`,
    genre: 'Aplicación móvil - Gestor de enlaces de Youtube',
    knowledges: [
      KNOWLEDGES.photoshop,
      KNOWLEDGES.html5,
      KNOWLEDGES.css3,
      KNOWLEDGES.js,
      KNOWLEDGES.sass,
      KNOWLEDGES.angular,
      KNOWLEDGES.ionic,
      KNOWLEDGES.git
    ],
    name: 'Youinstant',
    participants: [{
      image: '../../../assets/images/members/samu.jpg',
      name: 'Samuel',
      roles: ['Diseño UX/UI'],
      time: 8
    },
    {
      image: '../../../assets/images/members/jose.jpg',
      name: 'José Manuel',
      roles: ['Maquetación'],
      time: 20
    },
    {
      image: '../../../assets/images/members/edu.jpg',
      name: 'Eduardo',
      roles: ['Programación'],
      time: 80
    }],
    link: {
      external: false,
      url: 'youinstant'
    }
  },
  nierThirty: {
    background: 'nier-thirty',
    cta: 'Juega NieR:ThirtY',
    description: `
      De nuevo un trabajo resultado de un cumpleaños, esta vez para nuestro apreciado Samu. Se trata de una adaptación de
      NieR: Automata pero cargada de altas dosis de humor.
    `,
    genre: 'Videojuego - Arcade | Novela visual',
    knowledges: [
      KNOWLEDGES.html5,
      KNOWLEDGES.css3,
      KNOWLEDGES.js,
      KNOWLEDGES.sass,
      KNOWLEDGES.git
    ],
    music: '../../../assets/musics/nier-automata-menu.mp3',
    name: 'Nier:thirty',
    participants: [{
      image: '../../../assets/images/members/jose.jpg',
      name: 'José Manuel',
      roles: ['Maquetación', 'Programación'],
      time: 140
    },
    {
      image: '../../../assets/images/members/edu.jpg',
      name: 'Eduardo',
      roles: ['Guión', 'Sonido', 'Testing'],
      time: 10
    }],
    link: {
      external: false,
      url: 'cerca'
    }
  },
  wildsoft: {
    background: 'wildsoft',
    cta: 'Consigue los recursos UX/UI',
    description: `
      Wildsoft merece tener una gran ventana al mundo para darnos a conocer como profesionales. Juntos creamos esta experiencia
      y esperamos que te esté encantando.
    `,
    genre: 'Sitio web oficial',
    knowledges: [
      KNOWLEDGES.photoshop,
      KNOWLEDGES.adobeXD,
      KNOWLEDGES.html5,
      KNOWLEDGES.css3,
      KNOWLEDGES.js,
      KNOWLEDGES.react,
      KNOWLEDGES.git
    ],
    name: 'Wildsoft',
    participants: [{
      image: '../../../assets/images/members/samu.jpg',
      name: 'Samuel',
      roles: ['Diseño UX/UI', 'Maquetación', 'Programación'],
      time: 350
    },
    {
      image: '../../../assets/images/members/jose.jpg',
      name: 'José Manuel',
      roles: ['Programación'],
      time: 0
    }],
    link: {
      external: false,
      url: 'wildsoft-design.zip'
    }
  }
};
