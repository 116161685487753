import styled from "styled-components";
import type { ParagraphType } from "./types/paragraph.type";
import { DEVICES, PARAGRAPH_SIZES } from "../../utils";

const paragraphSizes = PARAGRAPH_SIZES as any as Record<string, string>;

export const ParagraphText = styled.p.attrs((props: ParagraphType) => props)`
  color: ${(props: ParagraphType) => props.color};
  font-family: ${(props: ParagraphType) => props.thin ? 'Outfit Extralight' : 'Outfit'};
  font-size: ${(props: ParagraphType) => paragraphSizes[props.size]};
  ${(props: ParagraphType) => props.italic && 'font-style: italic;'}
  margin: 0;
  ${(props: ParagraphType) => props.align && `text-align: ${props.align};`}
  @media (${DEVICES.laptopL}) {
		font-size: ${(props: ParagraphType) => paragraphSizes[props.size + 'Tablet']};
	}
	@media (${DEVICES.tablet}) {
		font-size: ${(props: ParagraphType) => paragraphSizes[props.size + 'Mobile']};
	}
`;
