export const TITLE_SIZES_NAMES = {
  title: 'title',
  titleMobile: 'titleMobile',
  titleTablet: 'titleTablet',
  sectionTitle: 'sectionTitle',
  sectionTitleMobile:'sectionTitleMobile',
  sectionTitleTablet: 'sectionTitleTablet',
  memberName: 'memberName',
  memberNameMobile: 'memberNameMobile',
  memberNameTablet: 'memberNameTablet',
  experienceTitle:'experienceTitle',
  experienceTitleMobile:'experienceTitleMobile',
  experienceTitleTablet: 'experienceTitleTablet'
};

export const TITLE_SIZES = {
  title: '12.5rem',
  titleMobile: '3.75rem',
  titleTablet: '7.5rem',
  sectionTitle: '3.125rem',
  sectionTitleMobile: '2rem',
  sectionTitleTablet: '3.125rem',
  memberName: '1.5rem',
  memberNameMobile: '1.25rem',
  memberNameTablet: '1.25rem',
  experienceTitle: '5rem',
  experienceTitleMobile: '1.5rem',
  experienceTitleTablet: '3.75rem'
};

export const PARAGRAPH_SIZES_NAMES = {
  subTitle: 'subTitle',
  subTitleTablet: 'subTitleTablet',
  subTitleMobile: 'subTitleMobile',
  sectionDescription: 'sectionDescription',
  sectionDescriptionTablet: 'sectionDescriptionTablet',
  sectionDescriptionMobile: 'sectionDescriptionMobile',
  memberRole: 'memberRole',
  memberRoleTablet: 'memberRoleTablet',
  memberRoleMobile: 'memberRoleMobile',
  memberQuote: 'memberQuote',
  memberQuoteTablet: 'memberQuoteTablet',
  memberQuoteMobile: 'memberQuoteMobile',
  experienceType: 'experienceType',
  experienceTypeTablet: 'experienceTypeTablet',
  experienceTypeMobile: 'experienceTypeMobile',
  experienceDescription: 'experienceDescription',
  experienceDescriptionTablet: 'experienceDescriptionTablet',
  experienceDescriptionMobile: 'experienceDescriptionMobile',
  participantInfo: 'participantInfo',
  participantInfoTablet: 'participantInfoTablet',
  participantInfoMobile:'participantInfoMobile',
  controls: 'controls',
  controlsTablet: 'controlsTablet',
  controlsMobile: 'controlsMobile',
  galleryTitle: 'galleryTitle',
  galleryTitleTablet: 'galleryTitleTablet',
  galleryTitleMobile: 'galleryTitleMobile',
  buttonText: 'buttonText',
  buttonTextTablet: 'buttonTextTablet',
  buttonTextMobile: 'buttonTextMobile',
  bigButtonText: 'bigButtonText',
  bigButtonTextTablet: 'bigButtonTextTablet',
  bigButtonTextMobile: 'bigButtonTextMobile',
  menuItem: 'menuItem',
  menuItemTablet: 'menuItemTablet',
  menuItemMobile: 'menuItemMobile',
};

export const PARAGRAPH_SIZES = {
  subTitle: '3.125rem',
  subTitleTablet: '3.125rem',
  subTitleMobile: '1.5rem',
  sectionDescription: '1.75rem',
  sectionDescriptionTablet: '1.5rem',
  sectionDescriptionMobile: '1rem',
  memberRole: '1.5rem',
  memberRoleTablet: '1.25rem',
  memberRoleMobile: '1.25rem',
  memberQuote: '1.125rem',
  memberQuoteTablet: '1rem',
  memberQuoteMobile: '1rem',
  experienceType: '2.25rem',
  experienceTypeTablet: '2.25rem',
  experienceTypeMobile: '1.25rem',
  experienceDescription: '1.75rem',
  experienceDescriptionTablet: '1.75rem',
  experienceDescriptionMobile: '1rem',
  participantInfo: '1.25rem',
  participantInfoTablet: '1.25rem',
  participantInfoMobile: '.875rem',
  controls: '1.25rem',
  controlsTablet: '1.25rem',
  controlsMobile: '1rem',
  galleryTitle: '2.25rem',
  galleryTitleTablet: '1.5rem',
  galleryTitleMobile: '1rem',
  buttonText: '1.5rem',
  buttonTextTablet: '1.5rem',
  buttonTextMobile: '1.5rem',
  bigButtonText: '1.75rem',
  bigButtonTextTablet: '1.75rem',
  bigButtonTextMobile: '1.25rem',
  menuItem: '1.75rem',
  menuItemTablet: '1.75rem',
  menuItemMobile: '1.25rem'
};

