import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Wildsoft from './entrypoint/wildsoft';

const root = document.getElementById('root');
if (!root) throw new Error('No se encontró el elemento root.');
ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <Wildsoft />
  </React.StrictMode>
);
