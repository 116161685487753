import { Knowledge } from "./knowledge.styled";
import { knowledgeType } from "./types/knowledge.type";
import { KNOWLEDGES_TITLES } from "../../utils/knowledges.utils";

const KnowledgesTitles = KNOWLEDGES_TITLES as any as Record<string, string>;

const KnowledgeComponent = ({name, size}: knowledgeType) => {
  return (
    <Knowledge
      src={`../../assets/images/knowledges/${name}.svg`}
      size={size}
      alt={KnowledgesTitles[name]}
      title={KnowledgesTitles[name]}
    />
  )
};

export default KnowledgeComponent;